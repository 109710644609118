import './App.css';
import './index.css';
import React from 'react';
import Home from './components/HomePage';
import MyBook from './components/myBook';
import MyApps from './components/appsPage';
import CardPix from './components/appPages/cardPix';
import Snube from './components/appPages/snube';
import StarTrailz from './components/appPages/starTrailz';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function HomePageWithLink() {
  return (
    <div>
      <Home />
    </div>
  );
}

function MyBookWithLink() {
  return (
    <div>
      <MyBook />
    </div>
  );
}

function MyAppsWithLink() {
  return (
    <div>
      <MyApps />
    </div>
  );
}

function CardPixWithLink() {
  return (
    <div>
      <CardPix />
    </div>
  );
}

function SnubeWithLink() {
  return (
    <div>
      <Snube />
    </div>
  );
}

function StarTrailzWithLink() {
  return (
    <div>
      <StarTrailz />
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePageWithLink />} />
        <Route path="/book" element={<MyBookWithLink />} />
        <Route path="/apps" element={<MyAppsWithLink />} />
        <Route path="/card-pix" element={<CardPixWithLink />} />
        <Route path="/snube" element={<SnubeWithLink />} />
        <Route path="/star-trailz" element={<StarTrailzWithLink />} />
      </Routes>
    </Router>
  );
}

export default App;
