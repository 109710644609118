import React from 'react';

const opacity = 1; // DELETE this once the rest of the code can come back
const ScrollToComponent = ({ id, children }) => {
    return (
        <div id={id} style={{ opacity }}>
            {children}
        </div>
    );
};

export default ScrollToComponent;
