import React from 'react';
import HeaderAndFooter from '../header-footer';
import AppLinks from './AppLinks';

function SnubePage() {
    return (
        <div>
            <HeaderAndFooter.Header classNames="w3-button w3-black w3-hover-purple nav-button" />
            <AppLinks />

            <div className="w3-container" id="card-pix">
                <div className="w3-content" style={{ maxWidth: "800px" }}>
                    <h2 className="w3-center">Snube</h2>
                    <img src="/images/SnubeImage.png" alt="3" className='app-image'></img>
                    <h3 className="w3-center w3-padding-64"><span className="w3-tag w3-wide w3-round-large">Privacy Policy</span></h3>
                    <p>
                        The Snube does not collect any personal information from any of your use of the Apps. If we have any of your personal information we will not willingly sell or give it to anyone, except if lawfully subpoenaed to produce it and then only after offering you a reasonable chance to challenge such subpoena if allowed by law.
                    </p>
                </div>
            </div>

            <HeaderAndFooter.Footer />
        </div>
    );
}

export default SnubePage;
