import '../index.css';
import React from 'react';
import HeaderAndFooter from './header-footer';
import AppLinks from './appPages/AppLinks';

function AppsPage() {
    return (
        <div>
            <HeaderAndFooter.Header classNames="w3-button w3-black w3-hover-purple nav-button" />
            <AppLinks />

            <div id="slider-container">
                <div id="image-slider">
                    <img src="/images/CardPixImage.png" alt="1"></img>
                    <img src="/images/StarTrailzImage.png" alt="2"></img>
                    <img src="/images/SnubeImage.png" alt="3"></img>
                    <img src="/images/CardPixImage.png" alt="1"></img>
                    <img src="/images/StarTrailzImage.png" alt="2"></img>
                    <img src="/images/SnubeImage.png" alt="3"></img>
                    <img src="/images/CardPixImage.png" alt="1"></img>
                    <img src="/images/StarTrailzImage.png" alt="2"></img>
                    <img src="/images/SnubeImage.png" alt="3"></img>
                    <img src="/images/CardPixImage.png" alt="1"></img>
                    <img src="/images/StarTrailzImage.png" alt="2"></img>
                    <img src="/images/SnubeImage.png" alt="3"></img>
                    <img src="/images/CardPixImage.png" alt="1"></img>
                    <img src="/images/StarTrailzImage.png" alt="2"></img>
                    <img src="/images/SnubeImage.png" alt="3"></img>
                    <img src="/images/CardPixImage.png" alt="1"></img>
                    <img src="/images/StarTrailzImage.png" alt="2"></img>
                    <img src="/images/SnubeImage.png" alt="3"></img>
                </div>
            </div>

            <HeaderAndFooter.Footer />
        </div>
    );
}

export default AppsPage;

