
import '../index.css';
import HeaderAndFooter from './header-footer';
import React from 'react';
// import ParticlesBackground from './particleBackground';
import ScrollToComponent from './scrollToComponent';

function MyBook() {

    return (
        <div>
            {/* <ParticlesBackground color="#ffc006" /> */}

            <HeaderAndFooter.Header classNames="w3-button w3-black w3-hover-amber nav-button"></HeaderAndFooter.Header>

            <img src="images/FairyTalesAndShadows.jpg" alt="Not loaded at the moment" style={{ paddingBottom: "80px" }} />

            <div className="w3-row w3-center w3-padding w3-black">
                <div className="w3-bar" style={{ width: "100%", overflow: "auto" }}>
                    <a href="https://www.amazon.com/Fairy-Tales-Shadows-Fantasy-Stories-ebook/dp/B0BV81XBQZ/ref=sr_1_1?crid=2NIOSMMA2S7J9&keywords=fairy+tales+and+shadows&qid=1676053325&s=books&sprefix=fairy+tales+and+shadow%2Cstripbooks%2C159&sr=1-1"
                        target="_blank" rel="noopener noreferrer"
                        className="w3-button w3-black w3-hover-amber nav-button" style={{ width: "25%" }}>VIEW IN KINDLE LIBRARY</a>
                </div>
            </div>

            <ScrollToComponent id="myBookScroll1">
                <div className="w3-container" id="about">
                    <div className="w3-content" style={{ maxWidth: "800px" }}>
                        <i className="fa-regular fa-dungeon"></i>
                        <h3 className="w3-center w3-padding-64"><span className="w3-tag w3-wide w3-round-large">About The Book</span></h3>
                        <p>Written in 2023</p>
                        <p>
                            Fairy Tales and Shadows is a book that I wrote to add some fantasy short stories with diverse characters
                            to our ever
                            growing collection of literature. Growing up, it would have been nice to get some exposure to a wider
                            range of the
                            beautiful types of people that we share this planet with. Fairy Tales and Mythology is one of the best
                            ways to do that
                            in my opinion so I tried to create this book with those ideas in mind.
                        </p>
                        <p>
                            I used OpenAI's chat bot to help me write the story. This sped up the process, as you might imagine, and
                            allowed me to
                            side step a lot of writer's block. I chose to write the book with AI assistence for convinience and because at the time that I wrote this book AI was just starting to explode and I was curious about its capabilities. In the end I had to write a fair bit myself to help bridge the
                            passages generated by
                            the AI since they were often less than a page in length. I had to revise the final document heavily but it was a really interesting experience. AI is
                            already used by
                            artists of other professionals and I think that writers will be able to start using it more as well.
                        </p>
                    </div>
                </div>
            </ScrollToComponent>

            <HeaderAndFooter.Footer></HeaderAndFooter.Footer>

        </div>
    );
}

export default MyBook;
