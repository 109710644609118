import React from 'react';
import PropTypes from 'prop-types';

const InfoBox = ({ title, subtitle, date, description, skills, funding, link, imageName, tabText, tabColor }) => {
  const styles = {
    container: {
      backgroundColor: '#1a1a1a',
      borderRadius: '15px',
      padding: '25px',
      color: '#fff',
      maxWidth: '1000px',
      margin: '20px auto',
      boxShadow: '0 8px 32px rgba(0, 0, 0, 0.3)',
      position: 'relative',
      textAlign: 'center',
      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
      border: '1px solid rgba(255, 255, 255, 0.1)',
      '&:hover': {
        transform: 'translateY(-5px)',
        boxShadow: '0 12px 40px rgba(0, 0, 0, 0.4)',
      }
    },
    tab: {
      position: 'absolute',
      bottom: '25px',
      right: '40px',
      backgroundColor: tabColor || '#FF5733',
      color: '#fff',
      padding: '8px 15px',
      borderRadius: '20px',
      fontSize: '14px',
      fontWeight: '600',
      textAlign: 'center',
      boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
      transition: 'transform 0.2s ease',
      '&:hover': {
        transform: 'scale(1.05)',
      }
    },
    header: {
      textAlign: 'center',
    },
    divider: {
      height: '2px',
      background: 'linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent)',
      margin: '20px 0',
    },
    contentWrapper: {
      display: 'flex',
      alignItems: 'flex-start',
      marginTop: '20px',
      textAlign: 'left',
      gap: '30px',
    },
    image: {
      width: '340px',
      height: '340px',
      borderRadius: '15px',
      objectFit: 'cover',
      boxShadow: '0 8px 24px rgba(0, 0, 0, 0.3)',
      transition: 'transform 0.3s ease',
      '&:hover': {
        transform: 'scale(1.02)',
      }
    },
    textContainer: {
      flex: 1,
    },
    title: {
      fontSize: '28px',
      fontWeight: '700',
      marginBottom: '10px',
      background: 'linear-gradient(45deg, #fff, #e0e0e0)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
    subtitle: {
      fontSize: '20px',
      fontWeight: '500',
      marginBottom: '5px',
      color: '#b0b0b0',
    },
    date: {
      fontSize: '16px',
      fontWeight: '400',
      marginBottom: '10px',
      color: '#888',
    },
    description: {
      fontSize: '16px',
      lineHeight: '1.6',
      marginBottom: '20px',
      color: '#e0e0e0',
    },
    skillsListTitle: {
      fontSize: '20px',
      fontWeight: '600',
      marginBottom: '12px',
      color: '#fff',
    },
    skillsList: {
      listStyleType: 'none',
      paddingLeft: '0',
      display: 'flex',
      flexWrap: 'wrap',
      gap: '10px',
    },
    skillItem: {
      fontSize: '14px',
      fontWeight: '400',
      padding: '6px 12px',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      borderRadius: '15px',
      color: '#e0e0e0',
      transition: 'background-color 0.2s ease',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
      }
    },
    link: {
      display: 'inline-block',
      marginTop: '20px',
      padding: '10px 25px',
      fontSize: '16px',
      color: '#fff',
      textDecoration: 'none',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      borderRadius: '25px',
      transition: 'all 0.3s ease',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        transform: 'translateY(-2px)',
      }
    },
  };

  let imageSrc;
  try {
    imageSrc = require(`../assets/images/${imageName}`);
  } catch (error) {
    console.error(`Image ${imageName} not found in assets/images.`);
    imageSrc = null;
  }

  return (
    <div>
        <div style={{
                height: '60px',
                width: '4px',
                backgroundColor: '#808080', // Customize color as per each tabColor
                margin: '0 auto 10px', // Centers the line and adds spacing below it
            }}></div>
        <div style={styles.container}>
        {tabText && <div style={styles.tab}>{tabText}</div>}
        <div style={styles.header}>
            <div style={styles.title}>{title}</div>
            {subtitle && <div style={styles.subtitle}>{subtitle}</div>}
            <div style={styles.date}>{date}</div>
            <div style={styles.divider}></div>
        </div>
        <div style={styles.contentWrapper}>
            {imageSrc && (
                link ? (
                    <a href={link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                        <img src={imageSrc} alt="Info Box" style={styles.image} />
                    </a>
                ) : (
                    <img src={imageSrc} alt="Info Box" style={styles.image} />
                )
            )}
            <div style={styles.textContainer}>
            <div style={styles.description}>{description}</div>
            {funding && <div style={styles.skillsListTitle}>Funding</div>}
            {funding && <ul style={styles.skillsList}>
                {funding.map((event, index) => (
                <li key={index} style={styles.skillItem}>
                    {event}
                </li>
                ))}
            </ul>}
            <div style={styles.skillsListTitle}>Skills/Tools</div>
            <ul style={styles.skillsList}>
                {skills.map((skill, index) => (
                <li key={index} style={styles.skillItem}>
                    {skill}
                </li>
                ))}
            </ul>
            {link && (
                <a href={link} style={styles.link} target="_blank" rel="noopener noreferrer">
                Learn More
                </a>
            )}
            </div>
        </div>
        </div>
    </div>
  );
};

InfoBox.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  date: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  skills: PropTypes.arrayOf(PropTypes.string).isRequired,
  link: PropTypes.string,
  imageName: PropTypes.string.isRequired,
  tabText: PropTypes.string, // New prop for tab text
  tabColor: PropTypes.string, // New prop for tab background color
};

export default InfoBox;
