import '../App.css';
import '../index.css';
import React, { useRef } from 'react';
import HeaderAndFooter from './header-footer';
import ScrollToComponent from './scrollToComponent';
import InfoBox from './infoBox';


function Home() {
    const infoSectionRef = useRef(null);

    const scrollToInfoSection = () => {
        infoSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div>
            <HeaderAndFooter.Header classNames="w3-button w3-black w3-hover-teal nav-button"></HeaderAndFooter.Header>

            <img src="images/BVAPresentation.JPG" alt="Not loaded at the moment" />
            <div className="w3-display-topmiddle w3-center" style={{ paddingTop: "100px" }}>
                <h3 className="w3-center w3-padding-16"><span className="w3-tag w3-wide w3-round-large w3-text-teal">Phillip Donnelly</span></h3>
                 {/* Scroll Button */}
                <div className="w3-center">
                    <button className="w3-button w3-teal w3-round-large pulsing-arrow" onClick={scrollToInfoSection}>
                        &#x2193;
                    </button>
                </div>
            </div>

            {/* Info Section */}
            <div ref={infoSectionRef} style={{ display: 'flex', flexDirection: 'column', paddingTop: "10px" }}>
                <div>
                    <InfoBox 
                        title="MEDITECH" 
                        date="2023-present" 
                        description="Working on the Scanning and Archiving team as a software developer." 
                        skills={["Magic", "Scrum", "SVN", "Development Pipelines"]}
                        imageName="Meditech.png"
                        tabText="Work"
                        tabColor="#00a8c2"
                    />
                </div>

                <div>
                    <InfoBox 
                        title="Amazon" 
                        subtitle="Deep Racer"
                        date="2022-2023" 
                        description="Worked as and SDE Level 4 full stack developer on the Deep Racer team contributing to automated track validation and documentation." 
                        skills={["Python", "Machine Learning", "GitHub", "Development Pipelines", "Scrum", "Agile"]}
                        link="https://aws.amazon.com/deepracer/?gclid=Cj0KCQiA88a5BhDPARIsAFj595hWIF61sEg_5MzuE6Nq-frhXdYzZpxWJoZUhgvvMH0sP2UsMVuf9UkaAo9NEALw_wcB&trk=ba68822c-4d74-4f28-b470-bb363c226519&sc_channel=ps&ef_id=Cj0KCQiA88a5BhDPARIsAFj595hWIF61sEg_5MzuE6Nq-frhXdYzZpxWJoZUhgvvMH0sP2UsMVuf9UkaAo9NEALw_wcB:G:s&s_kwcid=AL!4422!3!658520966051!!!g!!!19852661900!149878724300"
                        imageName="Amazon.png"
                        tabText="Work"
                        tabColor="#00a8c2"
                    />
                </div>

                <div>
                    <InfoBox 
                        title="Good Terms" 
                        date="2023" 
                        description="A four person start up internship working to make a google doc inspired platform designed specifically for laywers." 
                        skills={["React Native", "GitHub", "Cypress Automated Testing"]}
                        tabText="Internship"
                        tabColor="#ed7700"
                    />
                </div>

                <div>
                    <InfoBox 
                        title="Amazon Internship" 
                        subtitle="Asteroid"
                        date="2021" 
                        description="Worked as an intern on the AWS Asteroid team." 
                        skills={["Python", "GitHub", "Development Pipelines", "Scrum", "Agile"]}
                        imageName="Amazon.png"
                        tabText="Internship"
                        tabColor="#ed7700"
                    />
                </div>

                <div>
                    <InfoBox 
                        title="Caltech Research" 
                        subtitle="Published"
                        date="2020" 
                        description="Wrote Python code to parallelize software tasks that were executed for remote robotics systems to do material testing." 
                        skills={["Python", "Async Programming", "Cython", "GitHub"]}
                        link="https://onlinelibrary.wiley.com/doi/full/10.1002/admi.202101987"
                        imageName="Caltech.png"
                        tabText="Internship"
                        tabColor="#ed7700"
                    />
                </div>

                <div>
                    <InfoBox 
                        title="Carleton College" 
                        date="2019-2022" 
                        description="Bachelors in Computer Science." 
                        skills={["Quantum Computing", "Machine Learning", "Computational Biology", "Algorithms", "Software design", "Evolutionary Computation and Artificial Life", "ComputabilityandComplexity", "Programming Languages", "Data Structures", "The Math of Computer Science", "Organization and Architecture"]}
                        imageName="Carleton.png" 
                        tabText="Education"
                        tabColor="green"
                    />
                </div>

                <div>
                    <InfoBox 
                        title="Mystica" 
                        subtitle="In Development" 
                        date="2024" 
                        description="Mobile multiplayer game that uses API calls to a large language model for open ended text based combat and API calls to Google Maps for geo-locations." 
                        skills={["React Native", "PostgreSQL", "Large Language Models", "Google Maps API", "XCode"]}
                        link="https://mystica.cloud/"
                        imageName="Mystica.png"
                        tabText="Passion Project"
                        tabColor="purple"
                    />
                </div>

                 <div>
                    <InfoBox 
                        title="Star Trailz" 
                        subtitle="On App store"
                        date="2020-Present" 
                        description="Solo-developed a single player space game for mobile platforms." 
                        skills={["XCode", "Unity", "C#", "Marketing"]}
                        link="https://apps.apple.com/us/app/star-trailz/id1593538352"
                        imageName="StarTrailz.png"
                        tabText="Passion Project"
                        tabColor="purple"
                    />
                </div>

                <div>
                    <InfoBox 
                        title="Card Pix"
                        subtitle="On App store" 
                        date="2024" 
                        description="Solo-developed a multiplayer card game for mobile platforms. I utilize Google's image recognition APIs to categorize uploaded photos in game and convert them into playing cards." 
                        skills={["XCode", "Swift", "Google Image Recognition API"]}
                        link="https://pdpersonalpage.com/card-pix"
                        imageName="CardPix.png"
                        tabText="Passion Project"
                        tabColor="purple"
                    />
                </div>

                <div>
                    <InfoBox 
                        title="Snube" 
                        subtitle="On App store" 
                        date="2023" 
                        description="Solo-developed a single player arcade-style game for mobile platforms." 
                        skills={["XCode", "Unity", "C#",]}
                        link="https://pdpersonalpage.com/snube"
                        imageName="Snube.png"
                        tabText="Passion Project"
                        tabColor="purple"
                    />
                </div>

                <div>
                    <InfoBox 
                        title="Fairy Tails & Shadows"
                        subtitle="On Kindle and Audible"  
                        date="2023" 
                        description="A book of connected short stories I wrote with chat-gpt 3 to explore how effective LLM were at creative tasks." 
                        skills={["Writing", "Prompt Engineering"]}
                        link="https://www.amazon.com/Fairy-Tales-Shadows-Fantasy-Stories-ebook/dp/B0BV81XBQZ/ref=sr_1_1?crid=2NIOSMMA2S7J9&keywords=fairy+tales+and+shadows&qid=1676053325&s=books&sprefix=fairy+tales+and+shadow%2Cstripbooks%2C159&sr=1-1"
                        imageName="FairyTalesAndShadows.jpg"
                        tabText="Passion Project"
                        tabColor="purple"
                    />
                </div>

                <div>
                    <InfoBox 
                        title="Tell Your Tale" 
                        date="2020-2022" 
                        description="A 3d open world game with procedural landscapes and multiplayer game play." 
                        skills={["Unity", "C#", "Netcode/MLAPI", "GitHub"]}
                        funding={["$15,000 pledged through KickStarter"]}
                        link="https://www.kickstarter.com/projects/1863228575/tell-your-tale?ref=discovery&term=tell%20your%20tale&total_hits=16&category_id=35"
                        imageName="TellYourTale.png"
                        tabText="Passion Project"
                        tabColor="purple"
                    />
                </div>

                <div>
                    <InfoBox 
                        title="ParStar" 
                        date="2018-2019" 
                        description="Founded a Ed-Tech startup designed to help students stay engaged in virtual classes by providing tools to participate. My team won a spot in the Bronco Ventrue Accelerator at Santa Clara University and we won Carleton's start up competition." 
                        skills={["JavaScript", "HTML", "CSS", "Team Management", "Writed Communication", "Spoken Communication", "GitHub"]}
                        funding={["$10,000 from the Bronco Venture Accelerator", "$10,000 from the Carleton Start Up Competition"]}
                        imageName="BroncoVentureAccelerator.png"
                        tabText="Passion Project"
                        tabColor="purple"
                    />
                </div>
            </div>

            <HeaderAndFooter.Footer></HeaderAndFooter.Footer>

        </div>
    );
}

export default Home;