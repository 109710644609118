// AppLinks.js

import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using React Router for routing

const AppLinks = () => {
    return (
        <div className="app-links subheader">
            <Link to="/star-trailz">Star Trailz</Link>
            <Link to="/card-pix">Card Pix</Link>
            <Link to="/snube">Snube</Link>
        </div>
    );
};

export default AppLinks;
