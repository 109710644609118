import '../App.css';
import '../index.css';
import React, { useRef, useState } from 'react';
import HeaderAndFooter from './header-footer';
import ScrollToComponent from './scrollToComponent';
import InfoBox from './infoBox';
import GridBox from './GridBox';

function Home() {
    const infoSectionRef = useRef(null);
    const [activeFilter, setActiveFilter] = useState('all');
    const [viewMode, setViewMode] = useState('timeline'); // 'timeline' or 'grid'

    const scrollToInfoSection = () => {
        const offset = 100;
        const elementPosition = infoSectionRef.current.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - offset;
        
        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });
    };

    const filterButtons = [
        { label: 'All', color: '#ffffff', value: 'all' },
        { label: 'Work', color: '#00a8c2', value: 'Work' },
        { label: 'Internship', color: '#ed7700', value: 'Internship' },
        { label: 'Education', color: '#4CAF50', value: 'Education' },
        { label: 'Passion Project', color: '#9c27b0', value: 'Passion Project' }
    ];

    const filterProjects = (tabText) => {
        return activeFilter === 'all' || tabText === activeFilter;
    };

    const viewToggleStyles = {
        container: {
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
            marginBottom: '20px',
        },
        button: {
            backgroundColor: '#1a1a1a',
            color: '#ffffff',
            border: '2px solid #333333',
            padding: '10px 20px',
            borderRadius: '25px',
            cursor: 'pointer',
            fontSize: '16px',
            fontWeight: '500',
            transition: 'all 0.3s ease',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            boxShadow: '0 4px 15px rgba(0,0,0,0.2)',
        },
        activeButton: {
            backgroundColor: '#333333',
            borderColor: '#ffffff',
            boxShadow: '0 4px 15px rgba(0,0,0,0.3)',
        }
    };

    const gridStyles = {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
        gap: '30px',
        padding: '20px',
    };

    return (
        <div style={{ backgroundColor: '#121212', minHeight: '100vh' }}>
            <div style={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 1000 }}>
                <HeaderAndFooter.Header classNames="w3-button w3-black w3-hover-teal nav-button"></HeaderAndFooter.Header>
            </div>

            <div style={{ position: 'relative', height: '100vh', overflow: 'hidden' }}>
                <img 
                    src="images/BVAPresentation.JPG" 
                    alt="Not loaded at the moment" 
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        filter: 'brightness(0.7)',
                    }}
                />
                <div style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: '20%',
                    background: 'linear-gradient(to bottom, transparent, #121212)',
                    pointerEvents: 'none'
                }} />
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                    width: '100%',
                    zIndex: 1,
                }}>
                    <h3 style={{
                        fontSize: '48px',
                        fontWeight: '700',
                        color: '#fff',
                        textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
                        marginBottom: '30px',
                    }}>
                        <span 
                            onClick={scrollToInfoSection}
                            style={{
                                backgroundColor: '#009688',
                                padding: '15px 30px',
                                borderRadius: '30px',
                                boxShadow: '0 4px 15px rgba(0,0,0,0.3)',
                                cursor: 'pointer',
                                transition: 'all 0.3s ease',
                                display: 'inline-block',
                                color: '#ffffff',
                                '&:hover': {
                                    transform: 'translateY(-3px)',
                                    boxShadow: '0 6px 20px rgba(0,0,0,0.4)',
                                }
                            }}
                            onMouseOver={(e) => {
                                e.currentTarget.style.transform = 'translateY(-3px)';
                                e.currentTarget.style.boxShadow = '0 6px 20px rgba(0,0,0,0.4)';
                            }}
                            onMouseOut={(e) => {
                                e.currentTarget.style.transform = 'translateY(0)';
                                e.currentTarget.style.boxShadow = '0 4px 15px rgba(0,0,0,0.3)';
                            }}
                        >
                            Phillip Donnelly
                        </span>
                    </h3>
                    <button 
                        onClick={scrollToInfoSection}
                        style={{
                            backgroundColor: '#009688',
                            border: 'none',
                            color: '#ffffff',
                            padding: '15px 30px',
                            borderRadius: '30px',
                            fontSize: '24px',
                            cursor: 'pointer',
                            transition: 'all 0.3s ease',
                            boxShadow: '0 4px 15px rgba(0,0,0,0.3)',
                            '&:hover': {
                                transform: 'translateY(-3px)',
                                boxShadow: '0 6px 20px rgba(0,0,0,0.4)',
                            }
                        }}
                    >
                        &#x2193;
                    </button>
                </div>
            </div>

            <div 
                ref={infoSectionRef} 
                style={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    padding: '40px 20px',
                    maxWidth: '1200px',
                    margin: '0 auto',
                }}
            >
                {/* View Toggle */}
                <div style={viewToggleStyles.container}>
                    <button
                        onClick={() => setViewMode('timeline')}
                        style={{
                            ...viewToggleStyles.button,
                            ...(viewMode === 'timeline' ? viewToggleStyles.activeButton : {})
                        }}
                    >
                        <span>Timeline View</span>
                    </button>
                    <button
                        onClick={() => setViewMode('grid')}
                        style={{
                            ...viewToggleStyles.button,
                            ...(viewMode === 'grid' ? viewToggleStyles.activeButton : {})
                        }}
                    >
                        <span>Grid View</span>
                    </button>
                </div>

                {/* Filter Buttons */}
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '15px',
                    marginBottom: '40px',
                    flexWrap: 'wrap',
                }}>
                    {filterButtons.map((button) => (
                        <button
                            key={button.value}
                            onClick={() => setActiveFilter(button.value)}
                            style={{
                                backgroundColor: activeFilter === button.value ? button.color : 'rgba(255, 255, 255, 0.1)',
                                color: activeFilter === button.value 
                                    ? (button.value === 'all' ? '#121212' : '#ffffff') 
                                    : button.color,
                                border: `2px solid ${button.color}`,
                                padding: '10px 20px',
                                borderRadius: '25px',
                                cursor: 'pointer',
                                fontSize: '16px',
                                fontWeight: '500',
                                transition: 'all 0.3s ease',
                                boxShadow: '0 4px 15px rgba(0,0,0,0.2)',
                            }}
                            onMouseOver={(e) => {
                                if (activeFilter !== button.value) {
                                    e.currentTarget.style.backgroundColor = `${button.color}20`;
                                }
                            }}
                            onMouseOut={(e) => {
                                if (activeFilter !== button.value) {
                                    e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.1)';
                                }
                            }}
                        >
                            {button.label}
                        </button>
                    ))}
                </div>

                {/* Projects */}
                {viewMode === 'timeline' ? (
                    // Timeline View
                    <>
                        {filterProjects("Work") && (
                            <>
                                <div>
                                    <InfoBox 
                                        title="MEDITECH" 
                                        date="2023-present" 
                                        description="Fine tuning a chatbot coding assistant to accelerate on boarding and increase dev support and documentation access to our proprietary languages. Working on the Scanning and Archiving team as a software developer." 
                                        skills={["LLMs","Fine Tuning","Gemini","GPT-3.5","Next.js","Web Scraping","Web Development","Magic", "Scrum", "SVN", "Development Pipelines"]}
                                        link="https://meditech-chatbot-mvp.vercel.app/"
                                        imageName="Meditech.png"
                                        tabText="Work"
                                        tabColor="#00a8c2"
                                    />
                                </div>

                                <div>
                                    <InfoBox 
                                        title="Amazon" 
                                        subtitle="Deep Racer"
                                        date="2022-2023" 
                                        description="Worked as and SDE Level 4 full stack developer on the Deep Racer team contributing to automated track validation and documentation." 
                                        skills={["Python", "Machine Learning", "GitHub", "Development Pipelines", "Scrum", "Agile"]}
                                        link="https://aws.amazon.com/deepracer/?gclid=Cj0KCQiA88a5BhDPARIsAFj595hWIF61sEg_5MzuE6Nq-frhXdYzZpxWJoZUhgvvMH0sP2UsMVuf9UkaAo9NEALw_wcB&trk=ba68822c-4d74-4f28-b470-bb363c226519&sc_channel=ps&ef_id=Cj0KCQiA88a5BhDPARIsAFj595hWIF61sEg_5MzuE6Nq-frhXdYzZpxWJoZUhgvvMH0sP2UsMVuf9UkaAo9NEALw_wcB:G:s&s_kwcid=AL!4422!3!658520966051!!!g!!!19852661900!149878724300"
                                        imageName="Amazon.png"
                                        tabText="Work"
                                        tabColor="#00a8c2"
                                    />
                                </div>
                            </>
                        )}

                        {filterProjects("Internship") && (
                            <>
                                <div>
                                    <InfoBox 
                                        title="Good Terms" 
                                        date="2023" 
                                        description="A four person start up internship working to make a google doc inspired platform designed specifically for laywers." 
                                        skills={["React Native", "GitHub", "Cypress Automated Testing"]}
                                        tabText="Internship"
                                        tabColor="#ed7700"
                                    />
                                </div>

                                <div>
                                    <InfoBox 
                                        title="Amazon Internship" 
                                        subtitle="Asteroid"
                                        date="2021" 
                                        description="Worked as an intern on the AWS Asteroid team." 
                                        skills={["Python", "GitHub", "Development Pipelines", "Scrum", "Agile"]}
                                        imageName="Amazon.png"
                                        tabText="Internship"
                                        tabColor="#ed7700"
                                    />
                                </div>

                                <div>
                                    <InfoBox 
                                        title="Caltech Research" 
                                        subtitle="Published"
                                        date="2020" 
                                        description="Wrote Python code to parallelize software tasks that were executed for remote robotics systems to do material testing." 
                                        skills={["Python", "Async Programming", "Cython", "GitHub"]}
                                        link="https://onlinelibrary.wiley.com/doi/full/10.1002/admi.202101987"
                                        imageName="Caltech.png"
                                        tabText="Internship"
                                        tabColor="#ed7700"
                                    />
                                </div>
                            </>
                        )}

                        {filterProjects("Education") && (
                            <div>
                                <InfoBox 
                                    title="Carleton College" 
                                    date="2019-2022" 
                                    description="Bachelors in Computer Science." 
                                    skills={["Quantum Computing", "Machine Learning", "Computational Biology", "Algorithms", "Software design", "Evolutionary Computation and Artificial Life", "ComputabilityandComplexity", "Programming Languages", "Data Structures", "The Math of Computer Science", "Organization and Architecture"]}
                                    imageName="Carleton.png" 
                                    tabText="Education"
                                    tabColor="green"
                                />
                            </div>
                        )}

                        {filterProjects("Passion Project") && (
                            <>
                                <div>
                                    <InfoBox 
                                        title="Star Trailz" 
                                        subtitle="On App store"
                                        date="2020-Present" 
                                        description="Solo-developed a single player space game for mobile platforms." 
                                        skills={["XCode", "Unity", "C#", "Marketing"]}
                                        link="https://star-trailz-website.vercel.app/"
                                        imageName="StarTrailz.png"
                                        tabText="Passion Project"
                                        tabColor="purple"
                                    />
                                </div>

                                <div>
                                    <InfoBox 
                                        title="Card Pix"
                                        subtitle="On App store" 
                                        date="2024" 
                                        description="Solo-developed a multiplayer card game for mobile platforms. I utilize Google's image recognition APIs to categorize uploaded photos in game and convert them into playing cards." 
                                        skills={["XCode", "Swift", "Google Image Recognition API"]}
                                        link="https://card-pix-website.vercel.app/"
                                        imageName="CardPix.png"
                                        tabText="Passion Project"
                                        tabColor="purple"
                                    />
                                </div>

                                <div>
                                    <InfoBox 
                                        title="Mystica" 
                                        subtitle="In Development" 
                                        date="2024" 
                                        description="Mobile multiplayer game that uses API calls to a large language model for open ended text based combat and API calls to Google Maps for geo-locations." 
                                        skills={["React Native", "PostgreSQL", "Large Language Models", "Google Maps API", "XCode"]}
                                        imageName="Mystica.png"
                                        tabText="Passion Project"
                                        tabColor="purple"
                                    />
                                </div>

                                <div>
                                    <InfoBox 
                                        title="Snube" 
                                        subtitle="On App store" 
                                        date="2023" 
                                        description="Solo-developed a single player arcade-style game for mobile platforms." 
                                        skills={["XCode", "Unity", "C#",]}
                                        link="https://apps.apple.com/us/app/snube/id6451060423"
                                        imageName="Snube.png"
                                        tabText="Passion Project"
                                        tabColor="purple"
                                    />
                                </div>

                                <div>
                                    <InfoBox 
                                        title="Fairy Tails & Shadows"
                                        subtitle="On Kindle and Audible"  
                                        date="2023" 
                                        description="A book of connected short stories I wrote with chat-gpt 3 to explore how effective LLM were at creative tasks." 
                                        skills={["Writing", "Prompt Engineering"]}
                                        link="https://www.amazon.com/Fairy-Tales-Shadows-Fantasy-Stories-ebook/dp/B0BV81XBQZ/ref=sr_1_1?crid=2NIOSMMA2S7J9&keywords=fairy+tales+and+shadows&qid=1676053325&s=books&sprefix=fairy+tales+and+shadow%2Cstripbooks%2C159&sr=1-1"
                                        imageName="FairyTalesAndShadows.jpg"
                                        tabText="Passion Project"
                                        tabColor="purple"
                                    />
                                </div>

                                <div>
                                    <InfoBox 
                                        title="Tell Your Tale" 
                                        date="2020-2022" 
                                        description="A 3d open world game with procedural landscapes and multiplayer game play." 
                                        skills={["Unity", "C#", "Netcode/MLAPI", "GitHub"]}
                                        funding={["$15,000 pledged through KickStarter"]}
                                        link="https://www.kickstarter.com/projects/1863228575/tell-your-tale?ref=discovery&term=tell%20your%20tale&total_hits=16&category_id=35"
                                        imageName="TellYourTale.png"
                                        tabText="Passion Project"
                                        tabColor="purple"
                                    />
                                </div>

                                <div>
                                    <InfoBox 
                                        title="ParStar" 
                                        date="2018-2019" 
                                        description="Founded a Ed-Tech startup designed to help students stay engaged in virtual classes by providing tools to participate. My team won a spot in the Bronco Ventrue Accelerator at Santa Clara University and we won Carleton's start up competition." 
                                        skills={["JavaScript", "HTML", "CSS", "Team Management", "Writed Communication", "Spoken Communication", "GitHub"]}
                                        funding={["$10,000 from the Bronco Venture Accelerator", "$10,000 from the Carleton Start Up Competition"]}
                                        imageName="BroncoVentureAccelerator.png"
                                        tabText="Passion Project"
                                        tabColor="purple"
                                    />
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    // Grid View
                    <div style={gridStyles}>
                        {filterProjects("Work") && (
                            <>
                                <GridBox
                                    title="MEDITECH"
                                    date="2023-present"
                                    description="Fine tuning a chatbot coding assistant to accelerate on boarding and increase dev support and documentation access to our proprietary languages. Working on the Scanning and Archiving team as a software developer."
                                    skills={["LLMs","Fine Tuning","Gemini","GPT-3.5","Next.js","Web Scraping","Web Development","Magic", "Scrum", "SVN", "Development Pipelines"]}
                                    link="https://meditech-chatbot-mvp.vercel.app/"
                                    imageName="Meditech.png"
                                    tabText="Work"
                                    tabColor="#00a8c2"
                                />
                                <GridBox
                                    title="Amazon"
                                    subtitle="Deep Racer"
                                    date="2022-2023"
                                    description="Worked as and SDE Level 4 full stack developer on the Deep Racer team contributing to automated track validation and documentation."
                                    skills={["Python", "Machine Learning", "GitHub", "Development Pipelines", "Scrum", "Agile"]}
                                    link="https://aws.amazon.com/deepracer/"
                                    imageName="Amazon.png"
                                    tabText="Work"
                                    tabColor="#00a8c2"
                                />
                            </>
                        )}

                        {filterProjects("Internship") && (
                            <>
                                <GridBox
                                    title="Good Terms"
                                    date="2023"
                                    description="A four person start up internship working to make a google doc inspired platform designed specifically for laywers."
                                    skills={["React Native", "GitHub", "Cypress Automated Testing"]}
                                    imageName="computer-icon.png"
                                    tabText="Internship"
                                    tabColor="#ed7700"
                                />
                                <GridBox
                                    title="Amazon Internship"
                                    subtitle="Asteroid"
                                    date="2021"
                                    description="Worked as an intern on the AWS Asteroid team."
                                    skills={["Python", "GitHub", "Development Pipelines", "Scrum", "Agile"]}
                                    imageName="Amazon.png"
                                    tabText="Internship"
                                    tabColor="#ed7700"
                                />
                                <GridBox
                                    title="Caltech Research"
                                    subtitle="Published"
                                    date="2020"
                                    description="Wrote Python code to parallelize software tasks that were executed for remote robotics systems to do material testing."
                                    skills={["Python", "Async Programming", "Cython", "GitHub"]}
                                    link="https://onlinelibrary.wiley.com/doi/full/10.1002/admi.202101987"
                                    imageName="Caltech.png"
                                    tabText="Internship"
                                    tabColor="#ed7700"
                                />
                            </>
                        )}

                        {filterProjects("Education") && (
                            <GridBox
                                title="Carleton College"
                                date="2019-2022"
                                description="Bachelors in Computer Science."
                                skills={["Quantum Computing", "Machine Learning", "Computational Biology", "Algorithms", "Software design", "Evolutionary Computation and Artificial Life", "ComputabilityandComplexity", "Programming Languages", "Data Structures", "The Math of Computer Science", "Organization and Architecture"]}
                                imageName="Carleton.png"
                                tabText="Education"
                                tabColor="green"
                            />
                        )}

                        {filterProjects("Passion Project") && (
                            <>
                                <GridBox
                                    title="Star Trailz"
                                    subtitle="On App store"
                                    date="2020-Present"
                                    description="Solo-developed a single player space game for mobile platforms."
                                    skills={["XCode", "Unity", "C#", "Marketing"]}
                                    link="https://star-trailz-website.vercel.app/"
                                    imageName="StarTrailz.png"
                                    tabText="Passion Project"
                                    tabColor="purple"
                                />
                                <GridBox
                                    title="Card Pix"
                                    subtitle="On App store"
                                    date="2024"
                                    description="Solo-developed a multiplayer card game for mobile platforms. I utilize Google's image recognition APIs to categorize uploaded photos in game and convert them into playing cards."
                                    skills={["XCode", "Swift", "Google Image Recognition API"]}
                                    link="https://card-pix-website.vercel.app/"
                                    imageName="CardPix.png"
                                    tabText="Passion Project"
                                    tabColor="purple"
                                />
                                <GridBox
                                    title="Mystica"
                                    subtitle="In Development"
                                    date="2024"
                                    description="Mobile multiplayer game that uses API calls to a large language model for open ended text based combat and API calls to Google Maps for geo-locations."
                                    skills={["React Native", "PostgreSQL", "Large Language Models", "Google Maps API", "XCode"]}
                                    imageName="Mystica.png"
                                    tabText="Passion Project"
                                    tabColor="purple"
                                />
                                <GridBox
                                    title="Snube"
                                    subtitle="On App store"
                                    date="2023"
                                    description="Solo-developed a single player arcade-style game for mobile platforms."
                                    skills={["XCode", "Unity", "C#"]}
                                    link="https://apps.apple.com/us/app/snube/id6451060423"
                                    imageName="Snube.png"
                                    tabText="Passion Project"
                                    tabColor="purple"
                                />
                                <GridBox
                                    title="Fairy Tails & Shadows"
                                    subtitle="On Kindle and Audible"
                                    date="2023"
                                    description="A book of connected short stories I wrote with chat-gpt 3 to explore how effective LLM were at creative tasks."
                                    skills={["Writing", "Prompt Engineering"]}
                                    link="https://www.amazon.com/Fairy-Tales-Shadows-Fantasy-Stories-ebook/dp/B0BV81XBQZ/"
                                    imageName="FairyTalesAndShadows.jpg"
                                    tabText="Passion Project"
                                    tabColor="purple"
                                />
                                <GridBox
                                    title="Tell Your Tale"
                                    date="2020-2022"
                                    description="A 3d open world game with procedural landscapes and multiplayer game play."
                                    skills={["Unity", "C#", "Netcode/MLAPI", "GitHub"]}
                                    funding={["$15,000 pledged through KickStarter"]}
                                    link="https://www.kickstarter.com/projects/1863228575/tell-your-tale"
                                    imageName="TellYourTale.png"
                                    tabText="Passion Project"
                                    tabColor="purple"
                                />
                                <GridBox
                                    title="ParStar"
                                    date="2018-2019"
                                    description="Founded a Ed-Tech startup designed to help students stay engaged in virtual classes by providing tools to participate. My team won a spot in the Bronco Ventrue Accelerator at Santa Clara University and we won Carleton's start up competition."
                                    skills={["JavaScript", "HTML", "CSS", "Team Management", "Writed Communication", "Spoken Communication", "GitHub"]}
                                    funding={["$10,000 from the Bronco Venture Accelerator", "$10,000 from the Carleton Start Up Competition"]}
                                    imageName="BroncoVentureAccelerator.png"
                                    tabText="Passion Project"
                                    tabColor="purple"
                                />
                            </>
                        )}
                    </div>
                )}
            </div>

            <HeaderAndFooter.Footer></HeaderAndFooter.Footer>
        </div>
    );
}

export default Home;