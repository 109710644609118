import React from 'react';
import HeaderAndFooter from '../header-footer';
import AppLinks from './AppLinks';

function CardPixPage() {
    return (
        <div>
            <HeaderAndFooter.Header classNames="w3-button w3-black w3-hover-purple nav-button" />
            <AppLinks />

            <div className="w3-container" id="card-pix">
                <div className="w3-content" style={{ maxWidth: "800px" }}>
                    <h2 className="w3-center">Card Pix</h2>
                    <img src="/images/CardPixImage.png" alt="1" className='app-image'></img>
                    <h3 className="w3-center w3-padding-64"><span className="w3-tag w3-wide w3-round-large">Privacy Policy</span></h3>
                    <p><strong>Last Updated: Jan 16, 2024</strong></p>

                    <p><strong>1. Introduction</strong></p>
                    <p>Welcome to Card Pix. We are committed to respecting the privacy of all users. This Privacy Policy outlines our practices concerning the collection, use, and disclosure of your information when you use our app.</p>

                    <p><strong>2. Information We Collect</strong></p>
                    <p>Given our commitment to your privacy, we do not collect any personal data from our users. Our app is designed to allow gameplay, image submission, and messaging without collecting data such as your name, address, email address, or phone number.</p>

                    <p><strong>3. How We Use Your Information</strong></p>
                    <p>Since we do not collect personal data, we do not use or share your information.</p>

                    <p><strong>4. Image Submission</strong></p>
                    <p>Users can submit images from their camera roll to the app. These images are used solely for the purpose of gameplay and are not stored or used for any other purposes.</p>

                    <p><strong>5. Messaging</strong></p>
                    <p>Our app allows users to message other players. Please note that we do not monitor or store these messages.</p>

                    <p><strong>6. Data Security and User-Submitted Content</strong></p>
                    <p>Data Security: We take the security of our app seriously. However, as we do not collect personal data, there are no risks of such data being breached in terms of personal information.</p>
                    <p>User-Submitted Content: Our app allows users to submit images from their camera roll for use during gameplay. Please be aware that these images may be visible to other users during the course of a game. We urge you to exercise discretion and only upload images that you are comfortable having viewed by others in a public gameplay setting. Remember, any image you share during gameplay could be seen by other players, so it's important to consider the privacy and sensitivity of the content you choose to share.</p>
                    <p>While we strive to create a respectful and safe gaming environment, we are not responsible for the actions of other users.</p>

                    <p><strong>7. Changes to Our Privacy Policy</strong></p>
                    <p>We reserve the right to modify this privacy policy at any time. Changes and clarifications will take effect immediately upon their posting on the website.</p>

                    <p><strong>8. Contact Us</strong></p>
                    <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at <a href="mailto:philly.donnelly@gmail.com">philly.donnelly@gmail.com</a>.</p>
                    {/* -------------------------------------------------- */}
                    <h3 className="w3-center w3-padding-64"><span className="w3-tag w3-wide w3-round-large">Terms and Conditions</span></h3>
                    <p><strong>Last Updated: Jan 16, 2024</strong></p>

                    <p>Welcome to Card Pix!</p>
                    <p>These terms and conditions outline the rules and regulations for the use of Card Pix.</p>
                    <p>By accessing this app, we assume you accept these terms and conditions. Do not continue to use Card Pix if you do not agree to take all of the terms and conditions stated on this page.</p>

                    <p><strong>1. Terms of App Use</strong></p>
                    <p>Card Pix provides an online gaming platform that allows users to play games with each other, submit images from their camera roll, and message other players.</p>

                    <p><strong>2. User Responsibilities</strong></p>
                    <p>Content Submission: You are responsible for any content you submit to the app, including images. Do not upload content that you do not have the right to share or that is illegal, offensive, or infringes on the rights of others.</p>
                    <p>Fair Play: Engage in fair play and respectful conduct during gameplay and communication.</p>

                    <p><strong>3. Prohibited Uses</strong></p>
                    <p>In addition to other prohibitions as set forth in the Terms and Conditions, you are prohibited from using the app or its content for any unlawful purpose, to solicit others to perform or participate in any unlawful acts, to violate any international or local regulations, laws, rules, etc.</p>

                    <p><strong>4. Termination</strong></p>
                    <p>We may terminate or suspend access to our app immediately, without prior notice or liability, for any reason whatsoever, including, without limitation, if you breach the Terms and Conditions.</p>

                    <p><strong>5. Intellectual Property</strong></p>
                    <p>The app and its original content, features, and functionality are and will remain the exclusive property of Phillip Donnelly.</p>

                    <p><strong>6. Limitation of Liability</strong></p>
                    <p>Phillip Donnelly will not be liable for any indirect, incidental, special, consequential or punitive damages resulting from your access to or use of, or inability to access or use, the app.</p>

                    <p><strong>7. Amendments to Terms and Conditions</strong></p>
                    <p>We reserve the right to amend these Terms and Conditions at any time.</p>

                    <p><strong>8. Contact Information</strong></p>
                    <p>For any questions about these Terms and Conditions, please contact us at <a href="mailto:philly.donnelly@gmail.com">philly.donnelly@gmail.com</a>.</p>
                </div>
            </div>

            <HeaderAndFooter.Footer />
        </div>
    );
}

export default CardPixPage;
