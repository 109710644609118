import React from 'react';
import { Link } from 'react-router-dom';

class Header extends React.Component {
    render() {
        const { classNames } = this.props;
        return (
            <div className="w3-top">
                <div className="w3-row w3-center w3-padding w3-black">
                    <div className="w3-bar" style={{ width: "100%", overflow: "auto" }}>
                        <Link to="/" className={classNames} style={{ width: "15%" }}>HOME</Link>
                        <Link to="/apps" className={classNames} style={{ width: "15%" }}>MY APPS</Link>
                        <a href="https://onlinelibrary.wiley.com/doi/full/10.1002/admi.202101987" target="_blank" rel="noopener noreferrer" className={classNames} style={{ width: "15%" }}>RESEARCH</a>
                        <Link to="/book" className={classNames} style={{ width: "15%" }}>MY BOOK</Link>
                    </div>
                </div>
            </div>
        );
    }
}

class Footer extends React.Component {
    render() {
        return (
            <div className="w3-container" id="where" style={{ paddingBottom: "32px" }}>
                <div className="w3-content" style={{ maxWidth: "700px" }}>
                    {/* <h3 className="w3-center w3-padding-64"><span className="w3-tag w3-wide w3-round-large">Links</span></h3> */}
                    <div style={{ height: "100px" }} />
                    <ul className="w3-ul w3-center">
                        <li>philly.donnelly@gmail.com</li>
                        <li>Other resources - <a href="https://linktr.ee/philsindiegames" target="_blank" rel="noopener noreferrer">Linktree</a></li>
                    </ul>
                </div>
            </div>
        );
    }
}

const HeaderAndFooter = { Header, Footer };
export default HeaderAndFooter;