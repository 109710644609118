import React, { useState } from 'react';

function GridBox({ title, skills, link, imageName, tabText, tabColor }) {
    const [isHovered, setIsHovered] = useState(false);
    let imageSrc;
    try {
        imageSrc = require(`../assets/images/${imageName}`);
    } catch (error) {
        console.error(`Image ${imageName} not found in assets/images.`);
        imageSrc = null;
    }

    const styles = {
        container: {
            backgroundColor: '#1a1a1a',
            borderRadius: '15px',
            overflow: 'hidden',
            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.3)',
            transition: 'transform 0.3s ease',
            border: '1px solid rgba(255, 255, 255, 0.1)',
            position: 'relative',
        },
        imageContainer: {
            position: 'relative',
            width: '100%',
            paddingTop: '100%',
            overflow: 'hidden',
        },
        image: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            transition: 'transform 0.3s ease',
        },
        computerIcon: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#2a2a2a',
            color: '#666',
            fontSize: '48px',
        },
        content: {
            padding: '20px',
        },
        title: {
            fontSize: '24px',
            fontWeight: '700',
            color: '#fff',
            marginBottom: '20px',
            textAlign: 'center',
        },
        overlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            opacity: 0,
            transition: 'opacity 0.3s ease',
            padding: '20px',
            paddingTop: '60px',
            overflowY: 'auto',
        },
        overlayVisible: {
            opacity: 1,
        },
        skillsContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            gap: '10px',
            marginBottom: '20px',
            justifyContent: 'center',
            width: '100%',
        },
        skill: {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            color: '#fff',
            padding: '5px 12px',
            borderRadius: '15px',
            fontSize: '14px',
        },
        link: {
            display: 'inline-block',
            backgroundColor: tabColor || '#00a8c2',
            color: '#fff',
            padding: '10px 20px',
            borderRadius: '25px',
            textDecoration: 'none',
            transition: 'all 0.3s ease',
            marginTop: '10px',
            width: '100%',
            textAlign: 'center',
        },
        linkHover: {
            transform: 'translateY(-2px)',
            boxShadow: '0 4px 15px rgba(0,0,0,0.3)',
        },
        tab: {
            position: 'absolute',
            top: '20px',
            right: '20px',
            backgroundColor: tabColor || '#00a8c2',
            color: '#fff',
            padding: '5px 15px',
            borderRadius: '15px',
            fontSize: '14px',
            zIndex: 2,
        }
    };

    return (
        <div style={styles.container}>
            <div style={styles.tab}>{tabText}</div>
            <div 
                style={styles.imageContainer}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                {imageSrc ? (
                    <img 
                        src={imageSrc}
                        alt={title}
                        style={{
                            ...styles.image,
                            transform: isHovered ? 'scale(1.05)' : 'scale(1)'
                        }}
                    />
                ) : (
                    <div style={styles.computerIcon}>
                        <span role="img" aria-label="computer">💻</span>
                    </div>
                )}
                <div style={{
                    ...styles.overlay,
                    ...(isHovered ? styles.overlayVisible : {})
                }}>
                    <div style={styles.skillsContainer}>
                        {skills.map((skill, index) => (
                            <span key={index} style={styles.skill}>{skill}</span>
                        ))}
                    </div>
                    {link && (
                        <a 
                            href={link} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            style={styles.link}
                            onMouseOver={(e) => e.currentTarget.style.transform = 'translateY(-2px)'}
                            onMouseOut={(e) => e.currentTarget.style.transform = 'translateY(0)'}
                        >
                            Learn More
                        </a>
                    )}
                </div>
            </div>
            <div style={styles.content}>
                <h2 style={styles.title}>{title}</h2>
            </div>
        </div>
    );
}

export default GridBox; 